import 'bootstrap/dist/css/bootstrap.min.css';
import './css/home.css';
import './css/animate.css';
import './css/flaticon.css';
import './css/icomoon.css';
import './css/magnific-popup.css';
import './css/open-iconic-bootstrap.min.css';
import './css/owl.carousel.min.css';
import './css/owl.theme.default.min.css';
import './css/style.css';
import './css/css/mixins/_text-hide.css';
import './css/fontsawesome/css/all.min.css';
import './css/customcss/booking.css';
import './css/customcss/chalet.css';
import './css/NavMenu.css';
import './css/DestinationSearchArea.css';
import './css/SearchBar.css';
import './css/customcss/Admin.css';
import './css/customcss/loading.css';
import './css/ChaletAwayDatePicker.css';

import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import CookieConsent from './components/ContentParts/CookieConsent/CookieConsent';

// Lazy load components
import { Home } from './components/StaticPages/Home';

const Contact = lazy(() => import('./components/StaticPages/Contact'));
const About = lazy(() => import('./components/StaticPages/About'));
const Resort = lazy(() => import('./components/DynamicPages/Resorts/Resort'));
/*const Booking = lazy(() => import('./components/ContentParts/Booking/Booking'));*/
const PrivacyPolicy = lazy(() => import('./components/StaticPages/PrivacyPolicy'));
const Austria = lazy(() => import('./components/DynamicPages/Countries/Austria'));
const Switzerland = lazy(() => import('./components/DynamicPages/Countries/Switzerland'));
const Italy = lazy(() => import('./components/DynamicPages/Countries/Italy'));
const France = lazy(() => import('./components/DynamicPages/Countries/France'));
const Countries = lazy(() => import('./components/DynamicPages/Countries/Countries'));
const Chalet = lazy(() => import('./components/DynamicPages/Chalet/Chalet'));
const PageNotFound = lazy(() => import('./components/StaticPages/PageNotFound'));
const FindChalet = lazy(() => import('./components/DynamicPages/Search/ChaletSearch/FindChalet'));
const FindFlight = lazy(() => import('./components/DynamicPages/Search/Flight/FindFlight'));
const InspireMe = lazy(() => import('./components/DynamicPages/Search/InspireMe/InspireMe'));
const InspireMeShortTransfer = lazy(() => import('./components/DynamicPages/Search/InspireMe/ShortTransfer'));
const InspireMeChristmasSki = lazy(() => import('./components/DynamicPages/Search/InspireMe/ChristmasSki'));
const InspireMeNewYearSki = lazy(() => import('./components/DynamicPages/Search/InspireMe/NewYearSki'));
const InspireMeEasterSki = lazy(() => import('./components/DynamicPages/Search/InspireMe/EasterSki'));
const InspireMeHalfTermSki = lazy(() => import('./components/DynamicPages/Search/InspireMe/HalfTermSki'));
const InspireMeEarlySeasonSki = lazy(() => import('./components/DynamicPages/Search/InspireMe/EarlySeasonSki'));
const InspireMeLateSeasonSki = lazy(() => import('./components/DynamicPages/Search/InspireMe/LateSeasonSki'));

const InspireMeClosetoCalais = lazy(() => import('./components/DynamicPages/Search/InspireMe/ClosetoCalais'));
const InspireMeFamilyFriendly = lazy(() => import('./components/DynamicPages/Search/InspireMe/FamilyFriendly'));
const InspireMeScenicViews = lazy(() => import('./components/DynamicPages/Search/InspireMe/ScenicViews'));
const InspireMeLargeSkiingArea = lazy(() => import('./components/DynamicPages/Search/InspireMe/LargeSkiingArea'));
const InspireOffPiste = lazy(() => import('./components/DynamicPages/Search/InspireMe/OffPiste'));




const App = () => {
    return (
        <BrowserRouter>
            <Layout>
                <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/Austria" element={<Austria />} />
                        <Route path="/Findmeachalet" element={<FindChalet />} />
                        <Route path="/Findmeaflight" element={<FindFlight />} />
                        <Route path="/privacy" element={<PrivacyPolicy />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/MailingList" element={<Home />} />
                        <Route exact path="/:CountryName/:ResortName/Chalet" element={<Chalet />} />
                        <Route exact path="/:CountryName/:ResortName/:ChaletSlug" element={<Chalet />} />
                        <Route exact path="/:CountryName/:ResortName" element={<Resort />} />
                        <Route exact path="/Italy" element={<Italy />} />
                        <Route exact path="/France" element={<France />} />
                        <Route exact path="/Switzerland" element={<Switzerland />} />
                        <Route exact path="/countries" element={<Countries />} />
                        <Route path="/InspireMe" element={<InspireMe />} />
                        <Route exact path="/ShortTransfer" element={<InspireMeShortTransfer />} />
                        <Route exact path="/ChristmasSki" element={<InspireMeChristmasSki />} />
                        <Route exact path="/NewYearSki" element={<InspireMeNewYearSki />} />
                        <Route exact path="/EasterSki" element={<InspireMeEasterSki />} />
                        <Route exact path="/HalfTermSki" element={<InspireMeHalfTermSki />} />
                        <Route exact path="/EarlySeasonSki" element={<InspireMeEarlySeasonSki />} />
                        <Route exact path="/LateSeasonSki" element={<InspireMeLateSeasonSki />} />
                        <Route exact path="/CloseToCalais" element={<InspireMeClosetoCalais />} />
                        <Route exact path="/FamilyFriendly" element={<InspireMeFamilyFriendly />} />
                        <Route exact path="/ScenicViews" element={<InspireMeScenicViews />} />
                        <Route exact path="/LargeSkiingArea" element={<InspireMeLargeSkiingArea />} />
                        <Route exact path="/GoodOffPiste" element={<InspireOffPiste />} />
                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
                </Suspense>
                <CookieConsent />
            </Layout>
        </BrowserRouter>
    );
};

export default App;
